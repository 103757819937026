import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import {
  isAnalyticsAndroid,
  isDebug,
  isProduction,
  isTrackingAllowed,
} from "./utilities/helper";
import ChartsPage from "./pages/ChartsPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PageNotFound from "./pages/PageNotFound";
import ErrorPage from "./pages/ErrorPage";
import { initializeCleverTap } from "./utilities/cleverTapInit";

const RestrictedChat = React.lazy(() => import("./pages/RestrictedChat"));
const CalculateCalories = React.lazy(() => import("./pages/CalculateCalories"));

const ReportUploaded = React.lazy(() => import("./pages/ReportUploaded"));
const ObservationDetails = React.lazy(
  () => import("./pages/ObservationDetails")
);
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const Login = React.lazy(() => import("./pages/LoginV2"));
const Verify = React.lazy(() => import("./pages/VerifyV2"));
const Welcome = React.lazy(() => import("./pages/WelcomeV3"));
const HomeV2 = React.lazy(() => import("./pages/Home"));
const TestPage = React.lazy(() => import("./pages/TestPage"));
const DifferentialDiagnosisV2 = React.lazy(
  () => import("./pages/DifferentialDiagnosisV2")
);
const ConversationV4 = React.lazy(() => import("./pages/ConversationV4"));
const ConversationOnlySpeechV3 = React.lazy(
  () => import("./pages/ConversationOnlySpeechV3")
);
const InsertVitals = React.lazy(() => import("./pages/InsertVitals"));
const SummaryUpdatedV2 = React.lazy(() => import("./pages/SummaryUpdatedV2"));
const Terms = React.lazy(() => import("./pages/Terms"));
const DrJiviVoiceInput = React.lazy(() => import("./pages/DrJiviVoiceInput"));
const DoctorJiviHome = React.lazy(() => import("./pages/DoctorJiviHome"));
const DoctorJiviHistory = React.lazy(() => import("./pages/DoctorJiviHistory"));
const DoctorJiviHistoryDetails = React.lazy(
  () => import("./pages/DoctorJiviHistoryDetails")
);
const UploadImageDoc = React.lazy(() => import("./pages/UploadImageDoc"));
const Settings = React.lazy(() => import("./pages/Settings"));
const Feedback = React.lazy(() => import("./pages/Feedback"));
const Emergency = React.lazy(() => import("./pages/Emergency"));
const SentryError = React.lazy(() => import("./pages/SentryError"));
const AddEditSubUserV2 = React.lazy(() => import("./pages/AddEditSubUserV2"));
const LanguageSelector = React.lazy(() => import("./pages/LanguageSelector"));
const SpecialistListing = React.lazy(() => import("./pages/SpecialistListing"));
const SpecialistDetails = React.lazy(() => import("./pages/SpecialistDetails"));
const FreeChatV2 = React.lazy(() => import("./pages/FreeChatV2"));
const ReportDetails = React.lazy(() => import("./pages/ReportDetails"));
const ReportDetailsHomepage = React.lazy(
  () => import("./pages/ReportDetailsHomepage")
);
const ReportUpload = React.lazy(() => import("./pages/ReportUpload"));
const GenericContent = React.lazy(() => import("./pages/GenericContent"));
const RecentReports = React.lazy(() => import("./pages/RecentReports"));
const AccountMenu = React.lazy(() => import("./pages/AccountMenu"));
const ScreeningHome = React.lazy(() => import("./pages/ScreeningHome"));
const ScreeningUpload = React.lazy(() => import("./pages/ScreeningUpload"));
const ScreeningChat = React.lazy(() => import("./pages/ScreeningChat"));
const ScreeningDetails = React.lazy(() => import("./pages/ScreeningDetails"));
const ScreeningSymptoms = React.lazy(() => import("./pages/ScreeningSymptoms"));
const ScreeningHistory = React.lazy(() => import("./pages/ScreeningHistory"));

const SecondOpinionHome = React.lazy(() => import("./pages/SecondOpinionHome"));
const SecondOpinionUpload = React.lazy(
  () => import("./pages/SecondOpinionUpload")
);
const SecondOpinionChat = React.lazy(() => import("./pages/SecondOpinionChat"));
const SecondOpinionDetails = React.lazy(
  () => import("./pages/SecondOpinionDetails")
);
const WaitlistPage = React.lazy(() => import("./pages/Waitlist"));
const ReferralPage = React.lazy(() => import("./pages/Referral"));
const SecondOpinionSymptoms = React.lazy(
  () => import("./pages/SecondOpinionSymptoms")
);
const BloodReportDetailsV2 = React.lazy(
  () => import("./pages/BloodReportDetailsV2")
);
const TreatmentAndNextStepsPage = React.lazy(
  () => import("./pages/TreatmentAndNextStepsPage")
);
const Limitations = React.lazy(() => import("./pages/Limitations"));
const SecondOpinionHistory = React.lazy(
  () => import("./pages/SecondOpinionHistory")
);

const SearchPage = React.lazy(() => import("./pages/SearchPageV2"));
const SmartHealthHome = React.lazy(() => import("./pages/SmartHealthHome"));
const HealthIntegration = React.lazy(() => import("./pages/HealthIntegration"));
const HealthIntegrationPermission = React.lazy(
  () => import("./pages/HealthIntegrationPermission")
);
const ConnectedSmartHealthHome = React.lazy(
  () => import("./pages/ConnectedSmartHealthHome")
);
const StressSmartHealth = React.lazy(() => import("./pages/StressSmartHealth"));
const StrainSmartHealth = React.lazy(() => import("./pages/StrainSmartHealth"));
const RecoverySmartHealth = React.lazy(
  () => import("./pages/RecoverySmartHealth")
);

const HeartBeatPageV3 = React.lazy(() => import("./pages/HeartBeatV3"));
const StressDashboard = React.lazy(() => import("./pages/StressDashboard"));
const StressHistory = React.lazy(() => import("./pages/StressHistory"));

const HeartSmartHealth = React.lazy(() => import("./pages/HeartSmartHealth"));
const SearchPageImages = React.lazy(() => import("./pages/SearchPageImages"));
const SearchPageSources = React.lazy(() => import("./pages/SearchPageSources"));
const HealthBuddyHistory = React.lazy(
  () => import("./pages/HealthBuddyHistory")
);
const DermaDoc = React.lazy(() => import("./pages/DermaDoc"));
const DermaDocScan = React.lazy(() => import("./pages/DermaDocScan"));
const DermaDocRecentSessions = React.lazy(
  () => import("./pages/DermaDocRecentSessions")
);
const DermaDocChat = React.lazy(() => import("./pages/DermaDocChat"));
const DermaDocSelectArea = React.lazy(
  () => import("./pages/DermaDocSelectArea")
);
const DermaDocDetails = React.lazy(() => import("./pages/DermaDocDetails"));

const ReelPage = React.lazy(() => import("./pages/ReelPage"));
const AllReelsPage = React.lazy(() => import("./pages/AllReelsPage"));

const VideoPage = React.lazy(() => import("./pages/VideoPage"));
const AllVideosPage = React.lazy(() => import("./pages/AllVideosPage"));

const App = lazy(() => import("./App"));

if (isProduction()) {
  Sentry.init({
    dsn: "https://98483c1cef94077eb65494d7d049f0a5@sentry.jivihealth.org/6",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/api-server\.jivihealth\.org\//,
          /^https:\/\/doc\.jivihealth\.org\//,
          /^https:\/\/doc\.stage\.jivihealth\.org\//,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: import.meta.env.VITE_ENVIRONMENT,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.3,
    replaysOnErrorSampleRate: 1.0,
  });
}

if (
  isTrackingAllowed("clevertap") &&
  !isAnalyticsAndroid() &&
  (isProduction() || isDebug())
) {
  initializeCleverTap(isDebug());
}

if (
  isTrackingAllowed("mixpanel") &&
  !isAnalyticsAndroid() &&
  (isProduction() || isDebug())
) {
  mixpanel.init(import.meta.env.VITE_MIX_PANEL_KEY, {
    debug: !isProduction(),
    track_pageview: false,
    persistence: "localStorage",
  });
}

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "",
          element: <HomeV2 />,
        },
        {
          path: "/welcome",
          element: <Welcome />,
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/login/verify",
          element: <Verify />,
        },
        {
          path: "/terms-and-conditions",
          element: <Terms />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/:specialization/chat",
          element: <ConversationV4 />,
        },
        {
          path: "/chat",
          element: <ConversationV4 />,
        },
        {
          path: "/chat-avatar-full",
          element: <ConversationOnlySpeechV3 />,
        },
        {
          path: "/summary",
          element: <SummaryUpdatedV2 />,
        },
        {
          path: "/diagnosis",
          element: <DifferentialDiagnosisV2 />,
        },
        {
          path: "/:specialization/vitals",
          element: <InsertVitals />,
        },
        {
          path: "/vitals",
          element: <InsertVitals />,
        },
        {
          path: "/doc-jivi-home",
          element: <DoctorJiviHome />,
        },
        {
          path: "/doc-jivi-history",
          element: <DoctorJiviHistory />,
        },
        {
          path: "/:specialization/doc-jivi-history",
          element: <DoctorJiviHistory />,
        },
        {
          path: "/doc-jivi-session",
          element: <DoctorJiviHistoryDetails />,
        },
        {
          path: "/:specialization/doc-jivi-session",
          element: <DoctorJiviHistoryDetails />,
        },
        {
          path: "/free-text",
          element: <DrJiviVoiceInput />,
        },
        {
          path: "/:specialization/free-text",
          element: <DrJiviVoiceInput />,
        },
        {
          path: "/upload-image-doc",
          element: <UploadImageDoc />,
        },
        {
          path: "/:specialization/upload-image-doc",
          element: <UploadImageDoc />,
        },
        {
          path: "/settings",
          element: <Settings />,
        },
        {
          path: "/feedback/:sessionID",
          element: <Feedback />,
        },
        {
          path: "/emergency",
          element: <Emergency />,
        },
        {
          path: "/:specialization/emergency",
          element: <Emergency />,
        },
        {
          path: "/sentry-error",
          element: <SentryError />,
        },
        {
          path: "/:specialization/users/add",
          element: <AddEditSubUserV2 />,
        },
        {
          path: "/users/add",
          element: <AddEditSubUserV2 />,
        },
        {
          path: "/users/edit/:profile_id",
          element: <AddEditSubUserV2 />,
        },
        {
          path: "/:specialization/users/add",
          element: <AddEditSubUserV2 />,
        },
        {
          path: "/:specialization/users/edit/:profile_id",
          element: <AddEditSubUserV2 />,
        },
        {
          path: "/select-language",
          element: <LanguageSelector />,
        },
        {
          path: "/specialists",
          element: <SpecialistListing />,
        },
        {
          path: "/specialists/:specialist",
          element: <SpecialistDetails />,
        },
        {
          path: "/free-chat",
          element: <FreeChatV2 />,
        },
        {
          path: "/report-details",
          element: <ReportDetails />,
        },
        {
          path: "/reports/:report_type",
          element: <ReportDetailsHomepage />,
        },
        {
          path: "/reports/:report_type/upload",
          element: <ReportUpload />,
        },
        {
          path: "/:topic/details",
          element: <GenericContent />,
        },
        {
          path: "/recent-reports/:report_type",
          element: <RecentReports />,
        },
        {
          path: "/account",
          element: <AccountMenu />,
        },
        {
          path: "/screening",
          element: <ScreeningHome />,
        },
        {
          path: "/screening-symptoms",
          element: <ScreeningSymptoms />,
        },
        {
          path: "/screening-upload",
          element: <ScreeningUpload />,
        },
        {
          path: "/screening-chat",
          element: <ScreeningChat />,
        },
        {
          path: "/screening-history",
          element: <ScreeningHistory />,
        },
        {
          path: "/screening-details",
          element: <ScreeningDetails />,
        },
        {
          path: "/screening-history",
          element: <ScreeningHistory />,
        },
        {
          path: "/second-opinion",
          element: <SecondOpinionHome />,
        },
        {
          path: "/second-opinion-symptoms",
          element: <SecondOpinionSymptoms />,
        },
        {
          path: "/second-opinion-upload",
          element: <SecondOpinionUpload />,
        },
        {
          path: "/second-opinion-chat",
          element: <SecondOpinionChat />,
        },
        {
          path: "/second-opinion-history",
          element: <SecondOpinionHistory />,
        },
        {
          path: "/second-opinion-details",
          element: <SecondOpinionDetails />,
        },
        {
          path: "/second-opinion-history",
          element: <SecondOpinionHistory />,
        },
        {
          path: "/waitlist",
          element: <WaitlistPage />,
        },
        {
          path: "/referral",
          element: <ReferralPage />,
        },
        {
          path: "/report-uploaded",
          element: <ReportUploaded />,
        },
        {
          path: "/blood-report",
          element: <BloodReportDetailsV2 />,
        },
        {
          path: "/next-steps",
          element: <TreatmentAndNextStepsPage />,
        },
        {
          path: "/observations",
          element: <ObservationDetails />,
        },
        {
          path: "/contact-us",
          element: <ContactUs />,
        },
        {
          path: "/limitations",
          element: <Limitations />,
        },
        {
          path: "/restricted",
          element: <RestrictedChat />,
        },
        {
          path: "/search",
          element: <SearchPage />,
        },
        {
          path: "/charts",
          element: <ChartsPage />,
        },
        {
          path: "/smart-health",
          element: <SmartHealthHome />,
        },
        {
          path: "/smart-health/integrations",
          element: <HealthIntegration />,
        },
        {
          path: "/smart-health/integrations/:app",
          element: <HealthIntegrationPermission />,
        },
        {
          path: "smart-health-home",
          element: <ConnectedSmartHealthHome />,
        },
        {
          path: "smart-health-home/stress",
          element: <StressSmartHealth />,
        },
        {
          path: "smart-health-home/heart-health",
          element: <HeartSmartHealth />,
        },
        {
          path: "smart-health-home/strain",
          element: <StrainSmartHealth />,
        },
        {
          path: "smart-health-home/recovery",
          element: <RecoverySmartHealth />,
        },
        {
          path: "/calculate-calories",
          element: <CalculateCalories />,
        },
        {
          path: "/test",
          element: <TestPage />,
        },
        {
          path: "/stress",
          element: <HeartBeatPageV3 />,
        },
        {
          path: "stress/dashboard",
          element: <StressDashboard />,
        },
        {
          path: "/stress/history",
          element: <StressHistory />,
        },
        {
          path: "/search/images",
          element: <SearchPageImages />,
        },
        {
          path: "/search/sources",
          element: <SearchPageSources />,
        },
        {
          path: "/health-buddy-history",
          element: <HealthBuddyHistory />,
        },
        {
          path: "/derma-doc",
          element: <DermaDoc />,
        },
        {
          path: "/derma-doc/recent-sessions",
          element: <DermaDocRecentSessions />,
        },
        {
          path: "/derma-doc/select-area",
          element: <DermaDocSelectArea />,
        },
        {
          path: "/derma-doc/chat",
          element: <DermaDocChat />,
        },
        {
          path: "/derma-doc/details",
          element: <DermaDocDetails />,
        },
        {
          path: "/derma-doc/:type",
          element: <DermaDocScan />,
        },
        {
          path: "/reels/view",
          element: <ReelPage />,
        },
        {
          path: "/reels/:type",
          element: <AllReelsPage />,
        },
        {
          path: "/videos/view",
          element: <VideoPage />,
        },
        {
          path: "/videos/:type",
          element: <AllVideosPage />,
        },
        {
          path: "*",
          element: <PageNotFound />,
        },
      ],
    },
  ],
  {
    basename: import.meta.env.VITE_API_URL_PREFIX,
  }
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
