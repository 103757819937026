import React from "react";
import whiteArrow from "../assets/images/white_right.svg";
import { RedoOutlined } from "@ant-design/icons";

function PrimaryButton({
  key = "",
  text,
  textCustomClass = "",
  paddingY = "py-4",
  customClass = "px-8",
  width = null,
  icon = null,
  onClick,
  showIcon = true,
  color = "bg-primary",
  textColor = "text-white",
  justify = "justify-start",
  textSize = "text-label-large",
  borderRadius = "rounded-xl",
  loading = false,
  marginTop = "mt-4",
  disabled = false,
}) {
  return (
    <div
      key={key}
      onClick={loading || disabled ? () => {} : onClick}
      className={`${width ?? "w-full"} ${customClass} ${marginTop} ${paddingY} ${disabled ? "bg-gray-300" : color} ${borderRadius} justify-center items-center gap-2.5 flex cursor-pointer`}
    >
      <div className={`${justify} items-center gap-4 flex`}>
        {loading ? (
          <div className="w-full h-full flex justify-center items-center">
            <RedoOutlined className={`text-white ${textSize}`} spin />
          </div>
        ) : (
          <>
            <div className={`${textCustomClass} ${textColor} ${textSize}`}>
              {text}
            </div>
            {showIcon && (
              <img src={icon ?? whiteArrow} className="w-6 h-6 relative" />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default PrimaryButton;
